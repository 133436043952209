/** @jsxImportSource react */

import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, darkTheme, lightTheme, ConnectButton, useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { watchAccount, watchNetwork } from '@wagmi/core';
import { wagmiConfig } from "../../web3/wagmi";
import { chains } from '../../web3/wagmiChains';
import { useEffect } from 'react';
import { connectedWallet, onSupportedChain, openConnectWalletModalTrigger, openSwitchChainModalTrigger } from '../../web3/accountStore';
import { recentTxn, popRecentTxn } from '../../web3/recentTxnStore';
import type { AvatarComponentProps } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/AvatarContext';
import { analytics } from '../../analytics';

const runAll = (fns: Array<() => void>) => {
    return () => {
        fns.map(f => f());
    }
}

export const RainbowKitConnectWallet = () => {
    useEffect(() => {
        const clear1 = watchNetwork(nw => {
            const is = (nw.chain?.unsupported ?? true) == false
            onSupportedChain.set(is)
        })

        const clear2 = watchAccount(acc => {
            // console.log("updated connectedwallet", {acc})
            connectedWallet.set(acc);

            if (acc?.isConnected && !!acc.address) {
                analytics.setUserId(acc.address);
            } else if (acc?.isDisconnected) {
                analytics.logout()
            }
        });

        return runAll([clear1, clear2]);
    });

    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider
                id='rbk'
                chains={chains}
                modalSize={'wide'}
                appInfo={{ appName: 'luckydraw.gg' }}
                showRecentTransactions={true}
                theme={getTheme()}
                avatar={Avatar}
                locale='en'
            >

                <ConnectButton.Custom>
                    {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        mounted,
                        connectModalOpen,
                        chainModalOpen
                    }) => {
                        // Note: If your app doesn't use authentication, you
                        // can remove all 'authenticationStatus' checks
                        const ready = mounted;
                        const connected =
                            ready &&
                            account &&
                            chain;

                        const addRecentTransaction = useAddRecentTransaction();
                        useEffect(() => {
                            const clear1 = openConnectWalletModalTrigger.listen(() => {
                                // console.log("openConnectWalletModalTrigger", {connectModalOpen})
                                if (!connectModalOpen) {
                                    openConnectModal()
                                }
                            });

                            const clear2 = openSwitchChainModalTrigger.listen(() => {
                                // console.log("openSwitchChainModalTrigger", {chainModalOpen})
                                if (!chainModalOpen) {
                                    openChainModal()
                                }
                            });

                            const clear3 = recentTxn.subscribe(txn => {
                                if (txn) {
                                    addRecentTransaction(txn)
                                    popRecentTxn()
                                }
                            })

                            return runAll([clear1, clear2, clear3]);
                        })

                        return (
                            <div className='w-full'
                                {...(!ready && {
                                    'aria-hidden': true,
                                    'style': {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        userSelect: 'none',
                                    },
                                })}
                            >
                                {(() => {
                                    if (!connected) {
                                        return (
                                            <button onClick={openConnectModal} type="button" className='h-9 w-full py-1 tracking-tight 
                                            flex items-center gap-1 rounded-lg font-semibold text-base
                                            bg-emerald-600 hover:brightness-110  text-gray-100  transition-all'
                                                title='Connect Wallet'>
                                                <span className='grow sm:text-sm xl:text-base'>Connect Wallet</span>
                                            </button>
                                        );
                                    }

                                    if (chain.unsupported) {
                                        return (
                                            <button onClick={openChainModal} type="button" className='h-9 w-full py-1 px-3 
                                            bg-red-500 dark:bg-red-500 hover:bg-red-600 tracking-tight rounded-md
                                            border-2 border-white dark:border-slate-600 shadow-md hover:shadow-inner
                                            transition-all text-white font-medium' title='Change network'>
                                                Wrong network
                                            </button>
                                        );
                                    }

                                    return (
                                        <div className='w-full bg-white dark:bg-slate-800 rounded-lg
                                        hover:bg-slate-300 hover:dark:bg-slate-800  hover:shadow-lg transition-all'>

                                            <button onClick={openAccountModal} type="button"
                                                className='h-9 w-full flex items-center justify-center gap-1 font-semibold tracking-tight 
                                                text-indigo-800 dark:text-slate-200 hover:text-white group' title='View Connected Wallet'>

                                                <span className='grow py-1 px-2 sm:text-sm xl:text-base'>
                                                    {account.ensName
                                                        ? account.ensName
                                                        : account.displayName}

                                                </span>
                                                {/* TODO display DAI balance */}
                                            </button>
                                        </div>
                                    );
                                })()}
                            </div>
                        );
                    }}
                </ConnectButton.Custom>

            </RainbowKitProvider>
        </WagmiConfig>
    )
};


const getTheme = () => {
    // if (typeof window != 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        //dark mode 
        return {
            ...darkTheme({
                accentColor: "#6366f1",
                accentColorForeground: "#f1f5f9",
                overlayBlur: 'large',
            }),
            fonts: {
                body: 'inherit'
            },
        };
    // }

    return {
        ...lightTheme({
            ...lightTheme.accentColors.purple,
            overlayBlur: 'small',
        }),
        fonts: {
            body: 'inherit'
        }
    };
}

const Avatar = ({ address, ensImage, size }: AvatarComponentProps) => {
    return ensImage ? (
        <img
            src={ensImage}
            width={size}
            height={size}
            className='rounded-full'
        />
    ) : (
        <svg fill="currentColor" className="text-black w-full h-full p-2 bg-indigo-300" strokeWidth="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1em" width="1em" style={{ overflow: 'visible' }}><rect width="416" height="288" x="48" y="144" rx="48" ry="48" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32px"></rect><path d="M411.36 144v-30A50 50 0 0 0 352 64.9L88.64 109.85A50 50 0 0 0 48 159v49" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32px"></path><path d="M368 320a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z"></path></svg>
    );
}